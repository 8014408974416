import React from 'react';
import Tooltip from '@tippyjs/react';
import PropTypes from 'prop-types';
import {TEXT_COLOR_MODE} from './constant/mode';

export default function FuseLogoText({mode}) {
  const classMode =
    'font-[Comfortaa] text-xl ml-2 ' + (mode === 'DARK' ? 'text-jade-950 ' : 'text-near-white ');
  return (
    <Tooltip
      content={
        <div>
          <div className="mb-2">fuse is still in beta.</div>
          <div>It's feature are intended for evaluation</div>
        </div>
      }
    >
      <div className="relative">
        <span className={classMode}>fuse</span>
        <span className="absolute top-0 ml-1 rounded-full font-semibold bg-thistle-500 scale-95 -mb-2 px-1.5 py-0.5 text-xs inline-block">
          βeta
        </span>
      </div>
    </Tooltip>
  );
}
FuseLogoText.defaultProps = {
  mode: 'DARK',
};
FuseLogoText.propTypes = {
  mode: PropTypes.oneOf(TEXT_COLOR_MODE),
};
