import * as React from 'react';
import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';
import {cn} from '../utils/classes';

const buttonVariants = cva(
  'inline-flex items-center  justify-center whitespace-nowrap text-sm font-medium',
  {
    variants: {
      variant: {
        default:
        'disabled:bg-pewter-100 disabled:text-pewter-500 px-4 py-2 bg-jade-800 text-near-white rounded-sm hover:bg-jade-700 focus:!outline-jade-800 focus:!outline-offset-2 focus:!outline-solid focus:!outline-2 active:bg-jade-900',
        'outlined-zucchini': 'disabled:border-pewter-100 disabled:text-pewter-500 !border-1px px-4 py-2 border-solid border-jade-800 text-jade-800 rounded-sm hover:border-jade-700 focus:!outline-jade-500 focus:!outline-offset-1 focus:outline-solid focus:!outline-2 active:border-jade-900',
        'outlined-mint': 'disabled:border-pewter-100 disabled:text-pewter-500 !border-1px px-4 py-2 border-solid border-mint-800 text-mint-800 rounded-sm hover:border-mint-700 focus:!outline-mint-500 focus:!outline-offset-1 focus:outline-solid focus:!outline-2 active:border-mint-900',
        'outlined-destructive': 'disabled:border-pewter-100 disabled:text-pewter-500 !border-1px px-4 py-2 border-solid border-destructive-800 text-destructive-800 rounded-sm hover:border-destructive-700 focus:!outline-destructive-500 focus:!outline-offset-1 focus:outline-solid focus:!outline-2 active:border-destructive-900',
        'filled-zucchini': 'disabled:bg-pewter-100 disabled:text-pewter-500 px-4 py-2 bg-jade-800 text-near-white rounded-sm hover:bg-jade-700 focus:!outline-jade-800 focus:!outline-offset-2 focus:!outline-solid focus:!outline-2 active:bg-jade-900',
        'filled-mint': 'disabled:bg-pewter-100 disabled:text-pewter-500 px-4 py-2 bg-mint-300 text-jade-950 rounded-sm hover:bg-mint-200 focus:!outline-jade-500 focus:!outline-offset-2 focus:!outline-solid focus:!outline-2 active:bg-mint-400',
        'filled-jade': 'disabled:bg-pewter-100 disabled:text-pewter-500 px-4 py-2 bg-jade-600 text-near-white rounded-sm hover:bg-jade-500 focus:!outline-jade-600 focus:!outline-offset-2 focus:!outline-solid focus:!outline-2 active:bg-jade-700',
        'filled-destructive': 'disabled:bg-pewter-100 disabled:text-pewter-500 px-4 py-2 bg-destructive-500 text-near-white rounded-sm hover:bg-destructive-400 focus:!outline-destructive-800 focus:!outline-offset-2 focus:!outline-solid focus:!outline-2 active:bg-destructive-600',
        'link-jade':"hover:underline disabled:text-pewter-400 px-4 py-2 bg-transparent text-jade-500 rounded-sm focus:!outline-jade-500  focus:!outline-solid focus:!outline-1px",
        'link-zucchini':"hover:underline disabled:text-pewter-400 px-4 py-2 bg-transparent text-jade-800 rounded-sm focus:!outline-jade-500  focus:!outline-solid focus:!outline-1px",
        'link-pewter':"hover:underline disabled:text-pewter-400 px-4 py-2 bg-transparent text-pewter-800 rounded-sm focus:!outline-pewter-400  focus:!outline-solid focus:!outline-1px",
        'icon-jade':'text-center bg-transparent rounded-full text-jade-500 disabled:hover:bg-transparent disabled:text-pewter-400 hover:bg-jade-50 focus:!outline-1px focus:outline-solid focus:outline-jade-500 focus:bg-jade-100 active:bg-jade-100',
        'icon-zucchini':'text-center bg-transparent rounded-full text-jade-800 disabled:hover:bg-transparent disabled:text-pewter-400 hover:bg-jade-50 focus:!outline-1px focus:outline-solid focus:outline-jade-500 focus:bg-jade-100 active:bg-jade-100',
        'icon-pewter':'text-center bg-transparent rounded-full text-pewter-800 disabled:hover:bg-transparent disabled:text-pewter-400 hover:bg-pewter-50 focus:!outline-1px focus:outline-solid focus:outline-pewter-800 focus:bg-pewter-100 active:bg-pewter-100',
        'icon-destructive':'text-center bg-transparent rounded-full text-destructive-500 disabled:hover:bg-transparent disabled:text-pewter-400 hover:bg-pewter-50 focus:!outline-1px focus:outline-solid focus:outline-destructive-500 focus:bg-pewter-100 active:bg-pewter-100',
      },
      size: {
        default: 'h-10 px-4 py-2',
        "sm-icon":"h-4 w-4 p-0.5",
        "md-icon":'h-8 w-8 p-1',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({className, variant, size, asChild = false, ...props}, ref) => {
    const Comp = asChild ? Slot : 'button';
    return <Comp className={cn(buttonVariants({variant, size, className}))} ref={ref} {...props} />;
  }
);
Button.displayName = 'Button';

export {Button, buttonVariants};
