import React, {useState} from 'react';
import {Button} from './ui/Button';
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  BellIcon,
  BellSlashIcon,
  Cog6ToothIcon,
  EllipsisVerticalIcon,
  StarIcon,
  TagIcon,
  PlusIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import {Input} from './ui/Input';
import './ShowCase.css';
export function ShowCase() {
  let [disable, setDisable] = useState(false);
  const switchDisableModeFunc = function () {
    setDisable(!disable);
  };

  return (
    <div className="container m-1 text-jade-950 h-screen w-screen overflow-y-scroll">
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={disable}
          onChange={switchDisableModeFunc}
          className="!border-jade-800 !border-solid !border-1px rounded-sm"
        />
        <label>Disabled</label>
      </div>

      <fieldset
        className="border-jade-800 border-solid p-2"
        style={{
          borderWidth: '1px',
        }}
      >
        <legend>button(custom tailwind class)</legend>
        <div className="flex gap-2 flex-wrap">
          <button disabled={disable} className="btn-zucchini-outlined">
            btn-zucchini-outlined
          </button>
          <button disabled={disable} className="btn-mint-outlined">
            btn-mint-outlined
          </button>
          <button disabled={disable} className="btn-destructive-outlined">
            btn-destructive-outlined
          </button>
          <button disabled={disable} className="btn-zucchini-filled">
            btn-zucchini-filled
          </button>
          <button disabled={disable} className="btn-mint-filled">
            btn-mint-filled
          </button>
          <button disabled={disable} className="btn-jade-filled">
            btn-jade-filled
          </button>
          <button disabled={disable} className="btn-destructive-filled">
            btn-destructive-filled
          </button>
          <button disabled={disable} className="btn-zucchini-link">
            btn-zucchini-link
          </button>
          <button disabled={disable} className="btn-pewter-link">
            btn-pewter-link
          </button>
          <button disabled={disable} className="btn-jade-link">
            btn-jade-link
          </button>
          <div className="flex justify-center items-center gap-1">
            <button disabled={disable} className="btn-jade-icon">
              <PlusIcon />
            </button>
            <button disabled={disable} className="btn-jade-icon">
              <ArchiveBoxIcon />
            </button>
            <button disabled={disable} className="btn-jade-icon">
              <ArchiveBoxXMarkIcon />
            </button>
            <button disabled={disable} className="btn-jade-icon">
              <BellIcon />
            </button>
            <button disabled={disable} className="btn-jade-icon">
              <BellSlashIcon />
            </button>
            <button disabled={disable} className="btn-jade-icon">
              <Cog6ToothIcon />
            </button>
            <button disabled={disable} className="btn-jade-icon">
              <EllipsisVerticalIcon />
            </button>
            <button disabled={disable} className="btn-jade-icon">
              <StarIcon />
            </button>
            <button disabled={disable} className="btn-jade-icon">
              <TagIcon />
            </button>
            <button disabled={disable} className="btn-jade-icon">
              <UsersIcon />
            </button>

            <button disabled={disable} className="btn-jade-icon-sm">
              <UsersIcon />
            </button>
            <button disabled={disable} className="btn-jade-icon-sm">
              <EllipsisVerticalIcon />
            </button>
            <button disabled={disable} className="btn-jade-icon-sm">
              <StarIcon />
            </button>
            <button disabled={disable} className="btn-jade-icon-sm">
              <TagIcon />
            </button>
          </div>
          <div className="flex justify-center items-center gap-1">
            <button disabled={disable} className="btn-zucchini-icon">
              <PlusIcon />
            </button>
            <button disabled={disable} className="btn-zucchini-icon">
              <ArchiveBoxIcon />
            </button>
            <button disabled={disable} className="btn-zucchini-icon">
              <ArchiveBoxXMarkIcon />
            </button>
            <button disabled={disable} className="btn-zucchini-icon">
              <BellIcon />
            </button>
            <button disabled={disable} className="btn-zucchini-icon">
              <BellSlashIcon />
            </button>
            <button disabled={disable} className="btn-zucchini-icon">
              <Cog6ToothIcon />
            </button>
            <button disabled={disable} className="btn-zucchini-icon">
              <EllipsisVerticalIcon />
            </button>
            <button disabled={disable} className="btn-zucchini-icon">
              <StarIcon />
            </button>
            <button disabled={disable} className="btn-zucchini-icon">
              <TagIcon />
            </button>
            <button disabled={disable} className="btn-zucchini-icon">
              <UsersIcon />
            </button>

            <button disabled={disable} className="btn-zucchini-icon-sm">
              <UsersIcon />
            </button>
            <button disabled={disable} className="btn-zucchini-icon-sm">
              <EllipsisVerticalIcon />
            </button>
            <button disabled={disable} className="btn-zucchini-icon-sm">
              <StarIcon />
            </button>
            <button disabled={disable} className="btn-zucchini-icon-sm">
              <TagIcon />
            </button>
          </div>

          <div className="flex justify-center items-center gap-1">
            <button disabled={disable} className="btn-pewter-icon">
              <PlusIcon />
            </button>
            <button disabled={disable} className="btn-pewter-icon">
              <ArchiveBoxIcon />
            </button>
            <button disabled={disable} className="btn-pewter-icon">
              <ArchiveBoxXMarkIcon />
            </button>
            <button disabled={disable} className="btn-pewter-icon">
              <BellIcon />
            </button>
            <button disabled={disable} className="btn-pewter-icon">
              <BellSlashIcon />
            </button>
            <button disabled={disable} className="btn-pewter-icon">
              <Cog6ToothIcon />
            </button>
            <button disabled={disable} className="btn-pewter-icon">
              <EllipsisVerticalIcon />
            </button>
            <button disabled={disable} className="btn-pewter-icon">
              <StarIcon />
            </button>
            <button disabled={disable} className="btn-pewter-icon">
              <TagIcon />
            </button>
            <button disabled={disable} className="btn-pewter-icon">
              <UsersIcon />
            </button>

            <button disabled={disable} className="btn-pewter-icon-sm">
              <UsersIcon />
            </button>
            <button disabled={disable} className="btn-pewter-icon-sm">
              <EllipsisVerticalIcon />
            </button>
            <button disabled={disable} className="btn-pewter-icon-sm">
              <StarIcon />
            </button>
            <button disabled={disable} className="btn-pewter-icon-sm">
              <TagIcon />
            </button>
          </div>
          <div className="flex justify-center items-center gap-1">
            <button disabled={disable} className="btn-destructive-icon">
              <PlusIcon />
            </button>
            <button disabled={disable} className="btn-destructive-icon">
              <ArchiveBoxIcon />
            </button>
            <button disabled={disable} className="btn-destructive-icon">
              <ArchiveBoxXMarkIcon />
            </button>
            <button disabled={disable} className="btn-destructive-icon">
              <BellIcon />
            </button>
            <button disabled={disable} className="btn-destructive-icon">
              <BellSlashIcon />
            </button>
            <button disabled={disable} className="btn-destructive-icon">
              <Cog6ToothIcon />
            </button>
            <button disabled={disable} className="btn-destructive-icon">
              <EllipsisVerticalIcon />
            </button>
            <button disabled={disable} className="btn-destructive-icon">
              <StarIcon />
            </button>
            <button disabled={disable} className="btn-destructive-icon">
              <TagIcon />
            </button>
            <button disabled={disable} className="btn-destructive-icon">
              <UsersIcon />
            </button>

            <button disabled={disable} className="btn-destructive-icon-sm">
              <UsersIcon />
            </button>
            <button disabled={disable} className="btn-destructive-icon-sm">
              <EllipsisVerticalIcon />
            </button>
            <button disabled={disable} className="btn-destructive-icon-sm">
              <StarIcon />
            </button>
            <button disabled={disable} className="btn-destructive-icon-sm">
              <TagIcon />
            </button>
          </div>
        </div>
      </fieldset>

      <fieldset
        className="border-jade-800 border-solid p-2"
        style={{
          borderWidth: '1px',
        }}
      >
        <legend>button(React Component)</legend>
        <div className="flex gap-2 flex-wrap">
          <Button disabled={disable}>default</Button>
          <Button disabled={disable} variant={'outlined-zucchini'}>
            btn-zucchini-outlined
          </Button>
          <Button disabled={disable} variant={'outlined-mint'}>
            btn-mint-outlined
          </Button>
          <Button disabled={disable} variant={'outlined-destructive'}>
            btn-destructive-outlined
          </Button>
          <Button disabled={disable} variant={'filled-zucchini'}>
            btn-zucchini-filled
          </Button>
          <Button disabled={disable} variant={'filled-mint'}>
            btn-mint-filled
          </Button>
          <Button disabled={disable} variant={'filled-jade'}>
            btn-jade-filled
          </Button>
          <Button disabled={disable} variant={'filled-destructive'}>
            btn-destructive-filled
          </Button>
          <Button disabled={disable} variant={'link-zucchini'}>
            btn-zucchini-link
          </Button>
          <Button disabled={disable} variant={'link-pewter'}>
            btn-pewter-link
          </Button>
          <Button disabled={disable} variant={'link-jade'}>
            btn-jade-link
          </Button>

          <div className="flex justify-center items-center gap-1">
            <Button disabled={disable} size={'md-icon'} variant={'icon-jade'}>
              <PlusIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-jade'}>
              <ArchiveBoxIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-jade'}>
              <ArchiveBoxXMarkIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-jade'}>
              <BellIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-jade'}>
              <BellSlashIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-jade'}>
              <Cog6ToothIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-jade'}>
              <EllipsisVerticalIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-jade'}>
              <StarIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-jade'}>
              <TagIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-jade'}>
              <UsersIcon />
            </Button>

            <Button disabled={disable} size={'sm-icon'} variant={'icon-jade'}>
              <UsersIcon />
            </Button>
            <Button disabled={disable} size={'sm-icon'} variant={'icon-jade'}>
              <EllipsisVerticalIcon />
            </Button>
            <Button disabled={disable} size={'sm-icon'} variant={'icon-jade'}>
              <StarIcon />
            </Button>
            <Button disabled={disable} size={'sm-icon'} variant={'icon-jade'}>
              <TagIcon />
            </Button>
          </div>
          <div className="flex justify-center items-center gap-1">
            <Button disabled={disable} size={'md-icon'} variant={'icon-zucchini'}>
              <PlusIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-zucchini'}>
              <ArchiveBoxIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-zucchini'}>
              <ArchiveBoxXMarkIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-zucchini'}>
              <BellIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-zucchini'}>
              <BellSlashIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-zucchini'}>
              <Cog6ToothIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-zucchini'}>
              <EllipsisVerticalIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-zucchini'}>
              <StarIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-zucchini'}>
              <TagIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-zucchini'}>
              <UsersIcon />
            </Button>

            <Button disabled={disable} size={'sm-icon'} variant={'icon-zucchini'}>
              <UsersIcon />
            </Button>
            <Button disabled={disable} size={'sm-icon'} variant={'icon-zucchini'}>
              <EllipsisVerticalIcon />
            </Button>
            <Button disabled={disable} size={'sm-icon'} variant={'icon-zucchini'}>
              <StarIcon />
            </Button>
            <Button disabled={disable} size={'sm-icon'} variant={'icon-zucchini'}>
              <TagIcon />
            </Button>
          </div>
          <div className="flex justify-center items-center gap-1">
            <Button disabled={disable} size={'md-icon'} variant={'icon-pewter'}>
              <PlusIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-pewter'}>
              <ArchiveBoxIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-pewter'}>
              <ArchiveBoxXMarkIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-pewter'}>
              <BellIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-pewter'}>
              <BellSlashIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-pewter'}>
              <Cog6ToothIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-pewter'}>
              <EllipsisVerticalIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-pewter'}>
              <StarIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-pewter'}>
              <TagIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-pewter'}>
              <UsersIcon />
            </Button>

            <Button disabled={disable} size={'sm-icon'} variant={'icon-pewter'}>
              <UsersIcon />
            </Button>
            <Button disabled={disable} size={'sm-icon'} variant={'icon-pewter'}>
              <EllipsisVerticalIcon />
            </Button>
            <Button disabled={disable} size={'sm-icon'} variant={'icon-pewter'}>
              <StarIcon />
            </Button>
            <Button disabled={disable} size={'sm-icon'} variant={'icon-pewter'}>
              <TagIcon />
            </Button>
          </div>
          <div className="flex justify-center items-center gap-1">
            <Button disabled={disable} size={'md-icon'} variant={'icon-destructive'}>
              <PlusIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-destructive'}>
              <ArchiveBoxIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-destructive'}>
              <ArchiveBoxXMarkIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-destructive'}>
              <BellIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-destructive'}>
              <BellSlashIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-destructive'}>
              <Cog6ToothIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-destructive'}>
              <EllipsisVerticalIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-destructive'}>
              <StarIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-destructive'}>
              <TagIcon />
            </Button>
            <Button disabled={disable} size={'md-icon'} variant={'icon-destructive'}>
              <UsersIcon />
            </Button>

            <Button disabled={disable} size={'sm-icon'} variant={'icon-destructive'}>
              <UsersIcon />
            </Button>
            <Button disabled={disable} size={'sm-icon'} variant={'icon-destructive'}>
              <EllipsisVerticalIcon />
            </Button>
            <Button disabled={disable} size={'sm-icon'} variant={'icon-destructive'}>
              <StarIcon />
            </Button>
            <Button disabled={disable} size={'sm-icon'} variant={'icon-destructive'}>
              <TagIcon />
            </Button>
          </div>
        </div>
      </fieldset>
      <fieldset
        className="border-jade-800 border-solid p-2"
        style={{
          borderWidth: '1px',
        }}
      >
        <legend>input(custom tailwind class)</legend>
        <div className="flex gap-2 flex-wrap">
          <div className="flex gap-2 my-2">
            <input type="text" disabled={disable} className="input-outline" placeholder="outline" />
            <input
              type="text"
              disabled={disable}
              className="input-outline error"
              placeholder="outline error"
            />
          </div>
          <div className="flex gap-2 my-2">
            <input type="text" disabled={disable} className="input-filled" placeholder="filled" />
            <input
              type="text"
              disabled={disable}
              className="input-filled error"
              placeholder="filled error"
            />
          </div>
        </div>
      </fieldset>
      <fieldset
        className="border-jade-800 border-solid p-2"
        style={{
          borderWidth: '1px',
        }}
      >
        <legend>input(React Component)</legend>
        <div className="flex gap-2 flex-wrap">
          <div className="flex gap-2 my-2">
            <Input placeholder="default" disabled={disable} />
            <Input placeholder="default" disabled={disable} status={'error'} />
          </div>
          <div className="flex gap-2 my-2">
            <Input placeholder="filled" variant={'filled'} disabled={disable} />
            <Input placeholder="filled" variant={'filled'} disabled={disable} status={'error'} />
          </div>
          <div className="flex gap-2 my-2">
            <Input placeholder="outline" variant={'outline'} disabled={disable} />
            <Input placeholder="outline" variant={'outline'} disabled={disable} status={'error'} />
          </div>
        </div>
      </fieldset>
      <div className="flex">
        <div className="avatar xs">
          <img src="/avatar.png" />
        </div>

        <div className="avatar sm">
          <img src="/avatar.png" />
        </div>

        <div className="avatar md">
          <img src="/avatar.png" />
        </div>

        <div className="avatar lg">
          <img src="/avatar.png" />
        </div>

        <div className="avatar">
          <img src="/avatar.png" />
        </div>
        <img src="/avatar.png" className="avatar xs" />
        <img src="/avatar.png" className="avatar sm" />
        <img src="/avatar.png" className="avatar md" />
        <img src="/avatar.png" className="avatar lg" />
        <img src="/avatar.png" className="avatar" />
      </div>
    </div>
  );
}
