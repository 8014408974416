import logoLines from './assets/logo-lines.png';
import { TEXT_COLOR_MODE } from './constant/mode';
import FuseLogoText from './FuseLogoText';
import PropTypes from 'prop-types';

export default function Logo({withText = true, mode = 'DARK'}) {
  return (
    <div className="flex items-center">
      <img className="h-10" src={logoLines} style={{filter: 'hue-rotate(92deg)'}} alt="Logo" />
      {withText ? <FuseLogoText mode={mode} /> : null}
    </div>
  );
}
Logo.propTypes = {
  withText: PropTypes.bool,
  mode: PropTypes.oneOf(TEXT_COLOR_MODE),
};
