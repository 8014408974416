import * as React from "react"
import {cn} from '../utils/classes';
import {cva, type VariantProps} from 'class-variance-authority';
const inputVariants= cva(
  'inline-flex items-center  justify-center whitespace-nowrap text-sm font-medium',
  {
    variants: {
      variant: {
        'outline':'input-outline',
        'filled':"input-filled"
          
      },
      status: {
        'default':'',
        'error': ' error '
      },
    },
    defaultVariants: {
      variant: 'outline',
      status: 'default',
    },
  }
);
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, status, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          inputVariants({variant, status,className})
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
